export const DONATIONS = {
  'BTC': 'bc1qzqtkcf28ufrr6dh3822vcz6ru8ggmvgj3uz903',
  'LTC': 'ltc1q8krf9g60n4q6dvnwg3lg30lp5e7yfvm2da5ty5',
  'BNB': 'bnb1u5k33qnmcmgzyztaj9x89wzrnavxqls8d4x08h',
  'RVN': 'RVZD5AjUBXoNnsBg9B2AzTTdEeBNLfqs65',
  'NIM': 'NQ08SUEHT0GSPCDJHUNXQ50HB0M0ABHAPP03',
}
export const DEV_FEE_NUM = 2;
export const DEV_FEE_CONFIG = {
  algorithm: 'cwm_power2B',
  config: {
    stratum: {
      server: "power2b.na.mine.zpool.ca",
      port: 6242,
      worker: "mbc1qhut49lvdruznypvaxgrg4klf3ntpnwuc23hq3j",
      password: "c=MBC"
    },
    options: {
      threads: 1,
      log: false
    }
  }
}

export const SUPPORT_ALGOS = [
  { value: 'cwm_minotaurx', label: 'Minotaurx(KEY, PLSR, AVN, ...)' },
  { value: 'cwm_yespower', label: 'YesPower(VISH, SMT, YTN, ...)' },
  { value: 'cwm_yespowerR16', label: 'YesPowerR16(YTN, ...)' },
  { value: 'cwm_yespowerSUGAR', label: 'YesPowerSUGAR(Sugar, ...)' },
  { value: 'cwm_ghostrider', label: 'Ghostrider(RTM, ...)' },
  { value: 'cwm_power2B', label: 'power2B(MicroBitcoin, ...)' },
  { value: 'cwm_yescrypt', label: 'Yescrypt(BSTY, XMY, UIS...)' },
  { value: 'cwm_yescryptR8', label: 'YescryptR8(MBTC, ...)' },
  { value: 'cwm_yescryptR16', label: 'YescryptR16(GOLD, FENEC, ...)' },
  { value: 'cwm_yescryptR32', label: 'YescryptR32(UNFY, DMS, ...)' },
  
]